import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import '../../assets/css/styles.css';//css
import Loader from '../parts/Loader'
import helpers from '../../helpers/helpers';
import { httpRequest } from '../../helpers/httpService';

import Header from '../parts/Header';
 
function AliasRouter (){
    let { alias } = useParams();
    const [loading, setLoading] = useState(1);
    const [no_data, setNoData] = useState(0);
   
    useEffect(() => {
        let url = helpers.getUrl('url/get')
        const domain = window.location.host

        httpRequest(url, "POST", {
            postdata: {
                'alias': alias,
                'domain': domain
            },
        }, true)
        .then((res) => {
            window.location.href = res.data
        })
        .catch((err) => {
            setNoData(1)
            setLoading(0)
        }); 
    }, [])
    
    return (
        <>
        {
            no_data ?
            <>
            <Header />
            <div style={{backgroundColor: '#f7f7f7'}}>
                <div className="container">
                    <div className="row d-flex justify-content-center text-center mt-5">
                        <div className="col-lg-6">
                            {/* <i className="bi bi-exclamation-diamond-fill text-danger" style={{fontSize: '5rem'}}></i> */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="5rem" height="5rem" fill="currentColor" class="bi bi-exclamation-diamond-fill text-danger" viewBox="0 0 16 16"> <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/></svg>

                            <h1>Oops! 404 Not Found</h1>
                            <p>This Linkmo.co URL does not exist.</p>
                            <Link to="/" className='btn btn-outline-secondary mt-4'>Go to Linkmo.co</Link>
                        </div>
                    </div>
                </div>
            </div>
            </> 
            : <Loader isLoading={loading}/>
        }
        </>
    )
}
 
export default AliasRouter;