/**
 * Input Text Reusable Components
 */
import React from "react";

const RenderErrors = ({
        name, 
        className='',
        errors,
        style = {}
    }) => {
    
    if (errors && errors[name]) {
        return errors[name].map((err, i) => {
            return (
                <div key={i} className={className} style={style}>{err}</div>
            );
        })
    }

    return "";
};

export default RenderErrors;