import React, { useEffect, useState } from 'react';
import images from '../../helpers/images';

import httpService from '../../helpers/httpService';

function Footer(){

    return (
        <div id="footer-section" className='footer navbar-fixed-bottom ps-4 pe-4 ps-lg-5 pe-lg-5'>
            <p className='mb-0 copyright'>© {new Date().getFullYear()} <a href="https://daroy.net/" target='_new'>DAROY DIGITAL SOLUTION.</a> All&nbsp;Rights&nbsp;Reserved.</p>
            <p className='mb-0'>Made with <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-heart-fill text-danger" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"/></svg> in Ormoc, PH</p>
        </div>
    )
}

export default Footer;
