const form_data = {
  'alias': '', 
  'url': '', 
  'domain': 1,
}

const domain_list = [
  {
    'id': 1,
    'label': 'Lmo.me'
  },
  {
    'id': 1,
    'label': '[ Custom Domain - Coming Soon ]'
  }
]

const store = {
  form_data,
  domain_list
}

export default store