import React, { useEffect, useState } from 'react';
import store from '../../helpers/store';
import InputText from '../elements/InputText'
import Loader from '../parts/Loader'
import RenderErrors from '../elements/RenderErrors';
import helpers from '../../helpers/helpers';
import { httpRequest } from '../../helpers/httpService';
import DropDownSelection  from '../elements/DropDownSelection'

import Header from '../parts/Header';
import Footer from '../parts/Footer'
import QRCode from 'qrcode'
import Icons from '../../helpers/icons';
 
function HomePage ({readonly = false}){
    const [form_data, setForm] = useState(store.form_data);
    const [shorten_url, setShortenURL] = useState('');
    const [errors, setErrors] = useState([]);
    const [page_loading, setPageLoading] = useState(1);
    const [form_loading, setFormLoading] = useState(0);
    const [generated_qrcode, setQRCode] = useState('');
    const [copied, setCopied] = useState(0);
    const [ads_list, setAdsList] = useState([]);
    const [display_ads, setDisplayAds] = useState([]);
    const [current_item, setCurrentItem] = useState(0);

    useEffect(() => {
        let url = helpers.getUrl('url/domain')
        let domain = window.location.host

        if(domain === 'dev.linkmo.co' || domain === 'beta.linkmo.co' || domain === 'staging.linkmo.co'){
            domain = 'linkmo.co'
        }

        httpRequest(url, "POST", {
            postdata: {
                'domain': domain
            },
        }, true)
        .then((res) => {
            if(res.domain_type !== 'home'){
                window.location.href = res.home_url
                return;
            }
            setPageLoading(0)    

            if(res.hasOwnProperty('ads')){
                setAdsList(res.ads)
                setTimeout(function(){
                    showAdsItem()
                }, 20000);
            }
        })
        .catch((err) => {
            setPageLoading(0)
        });
    }, [])

    useEffect(() => {
        let ads =  []

        if(Object.keys(ads_list).length){
            ads = Object.entries(ads_list).map(([index, data]) => {
                let add_class = ''
                if(Number(index) >= 1){
                    add_class = ' d-none'
                }

                return <div className={'ads-container col-12 col-md-6'+add_class} key={index}>
                                <a href={data.url} rel="nofollow">
                                    <div className='ads-item'>
                                        <img src={data.img} className='img-fluid ads-img' loading="lazy" alt="ads image" />
                                        <button type='button' className='btn btn-danger'>BUY NOW</button>
                                        <span>Ad</span>
                                    </div>
                                </a>
                            </div>
            })
        }

        setDisplayAds(<div className="row d-flex justify-content-center">{ads}</div>)
    }, [ads_list])

    useEffect(() => {
        setTimeout(function(){
            showAdsItem()
        }, 20000);
    }, [current_item])

    function showAdsItem() {
        const ads_conatiner = document.getElementsByClassName("ads-container")

        for (var i = 0; i < ads_conatiner.length; i++) {
            ads_conatiner[i].classList.remove("d-none")
            ads_conatiner[i].classList.add("d-none")
        }

        let total_ads = ads_conatiner.length - 1;//For Array
        let counter = current_item;
        counter++;
        if(counter > total_ads){
            counter = 0
        }

        ads_conatiner[counter].classList.remove("d-none")

        setCurrentItem(counter)
    }

    function generateQRCode(url = ''){
        if(!url) return '';
        QRCode.toDataURL(url, { errorCorrectionLevel: 'H', width: 1000, margin: 2 }, function (err, url) {
            if(url){
                setQRCode(url)
            }
        })
    }

    function inputChange(e) {
        let { value, name } = e.target;

        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                value = 1;

                if(name == 'warranty_status') value = 'active'
            } else {
                value = 0;

                if(name == 'warranty_status') value = 'expired'
            }
        }
        else if (e.target.type === "file") {
          value = e.target.files[0]
        }

        let new_state = { [name]: value }

        setForm({ ...form_data, ...new_state });
    }
    

    function getFormData(status = 'draft'){
        let form = new FormData();

        for(var key in form_data) {
            form.append(key, form_data[key]);
        }

        return form
    }

    function copy(){
        var copyText = document.getElementById("shorten-link");
        copyText.select();
        copyText.setSelectionRange(0, 99999); // For mobile devices
        navigator.clipboard.writeText(copyText.value);

        setCopied(1)
        setTimeout(() => {
            setCopied(0)
          }, "10000");   
    }

    
    function reset(e){
        setShortenURL(0)
        setForm(store.form_data)
        setErrors([])
        setQRCode('')
    }

    function submit(e){
        // const captcha = recaptchaRef.current.getValue();
        // if(!captcha){
        //     setErrors( {
        //         recaptcha: ['The recaptcha is required.']
        //     } );
        //     return;
        // }
        // recaptchaRef.current.reset() //Reinitialize reCaptcha

        let url = helpers.getUrl('url/generate')
        const validate = validateForm();
        if(Object.keys(validate).length) {
            return;
        }

        setFormLoading(1)

        httpRequest(url, "POST", {
            postdata: form_data,
        }, true)
        .then((res) => {
            setFormLoading(0)
            setShortenURL(res.data)
            generateQRCode(res.data)
        })
        .catch((err) => {
            setFormLoading(0)
            setShortenURL(0)
            
            if(err.data){
                setErrors( err.data );
            }else{
                setErrors([])
            }
        }); 
    }

    function validateForm(e){
        let new_errors = {...errors}

        let strRegex = new RegExp(/^[a-zA-Z0-9-_]+$/i);

    
        if(!form_data.url){
            new_errors['url'] = ["The url field is required."]
        }
        else if(!helpers.isValidUrl(form_data.url)){
            new_errors['url'] = ["The url field must be a valid URL."]
        }
        else if(form_data.url.length > 2000){
            new_errors['url'] = ["The url field must not be greater than 2000 characters."]
        }
        else{
            delete new_errors['url'];
        }
    
        if(!form_data.domain){
            new_errors['domain'] = ["The domain field is required."]
        }
        else if(!Number(form_data.domain)){
            new_errors['domain'] = ["The domain field must be numeric."]
        }
        else{
            delete new_errors['domain'];
        }
    
        if(form_data.alias){
        // if(!form_data.alias){
        //     new_errors['alias'] = ["The alias field is required."]
        // }
            if(!strRegex.test(form_data.alias)){
                new_errors['alias'] = ["The alias field format is invalid."]
            }
            else if(form_data.alias.length < 7){
                new_errors['alias'] = ["The alias field must be at least 7 characters."]
            }
            else if(form_data.alias.length > 100){
                new_errors['alias'] = ["The alias field must not be greater than 100 characters."]
            }
            else{
                delete new_errors['alias'];
            }
        }
    
        setErrors(new_errors);
    
        return new_errors;
    }

    function removeErrors(e){
        let { name } = e.target;
        let newErrors = {...errors}

        delete newErrors[name];

        setErrors(newErrors);
    }
    
    return (
        <>
        {
            page_loading ?
            <Loader isLoading={page_loading}/> :
            <>
                <Header />
                <div style={{backgroundColor: '#f7f7f7'}}  id="wrap">
                    <div className="container-fluid home-app ps-4 pe-4 ps-md-5 pe-md-5"> 
                    {/* ps-4 pe-4 ps-md-5 pe-md-5 */}
                        <div className="row d-flex justify-content-between pt-5 pb-2">
                            <div className="col-xs-12 col-lg-5 mb-3">
                                
                                {/* <h1>Shorten a long link</h1> */}
                                {/* <p>Create free, shorter URLs and QR codes with LinkMo.</p> */}
                                <h1>URL Shortening with Custom QR Code</h1>
                                <p>Create Free Short URLs & QR Codes - Boost Engagement & Track Easily</p>

                                <form>
                                    <div className="card mb-3" style={{position: 'relative'}}>
                                        <Loader isLoading={form_loading}/>
                                        <div className="card-body">
                                            { !shorten_url ?
                                            <div className="row">
                                                <div className="col-12 form-group">
                                                    <label htmlFor="url" className='mb-2 fw-bold'><Icons icon="link" classes='me-1' />Enter a Long URL</label>
                                                    <InputText
                                                        id="url" 
                                                        name="url" 
                                                        value={form_data.url}
                                                        tagClass="form-control mb-0"
                                                        onChange={inputChange}
                                                        onFocus={removeErrors}
                                                        placeholder="E.g: https://example-long-link.com/url-slug"
                                                        type="text"
                                                        readOnly={readonly}
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="url" 
                                                        className="text-danger"
                                                        style={{fontSize: '.85rem', marginTop: '5px'}}
                                                    />
                                                </div>
                                                <div className="col-md-5 form-group mt-3">
                                                    <label htmlFor="domain" className='mb-2 fw-bold'><Icons icon="globe" classes='me-1' />Domain</label>
                                                    <DropDownSelection
                                                        id="domain"
                                                        tagClass="form-select"
                                                        name='domain' 
                                                        placeholder='Select Domain'
                                                        value={form_data.domain}  
                                                        onChange={inputChange}
                                                        onFocus=""
                                                        optionList={store.domain_list}
                                                        optionProps ={{value:'id', label:'label'}}
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="domain" 
                                                        className="text-danger"
                                                        style={{fontSize: '.85rem', marginTop: '5px'}}
                                                    />
                                                </div>
                                                <div className="col-md-7 form-group mt-3">
                                                    <label htmlFor="alias" className='mb-2 fw-bold'><Icons icon="pencil-square" classes='me-1' />Alias (Optional)</label>
                                                    <InputText
                                                        id="alias" 
                                                        name="alias" 
                                                        value={form_data.alias}
                                                        tagClass="form-control mb-0"
                                                        onChange={inputChange}
                                                        onFocus={removeErrors}
                                                        placeholder="E.g: new-alias"
                                                        type="text"
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="alias" 
                                                        className="text-danger"
                                                        style={{fontSize: '.85rem', marginTop: '5px'}}
                                                    />
                                                </div>
                                                <div className="col-12 mt-3">
                                                    <small className='mb-0'>E.g: http://{helpers.displayDomain(store.domain_list, form_data.domain)}/new-alias</small>
                                                </div>
                                            </div> : ''
                                            }
                                            { shorten_url ?
                                                <>
                                                <div className="row">
                                                    <div className="col-12 form-group mb-3">
                                                        <label htmlFor="shorten-link" className='mb-2 fw-bold'><Icons icon="stars" classes='me-1' />Shorten URL</label>
                                                        <div className="input-group">
                                                            <input id="shorten-link"  className='form-control mb-0' readOnly={1} value={shorten_url} style={{backgroundColor: '#f9f9f9'}} />
                                                            <button className="btn btn-outline-primary" type="button" onClick={copy} id="btn-copy">Copy</button>
                                                        </div>
                                                        {copied ? <small className='text-success'>Copied Text!</small> : ''}
                                                    </div>
                                                </div>
                                                </> : ''
                                            }
                                            {
                                                generated_qrcode ?
                                                <div className="row">
                                                    <div className="col-md-12 form-group mb-2">
                                                        <label htmlFor="shorten-link" className='fw-bold'><Icons icon="qr-code" classes='me-1' />QR Code</label>
                                                    </div>
                                                    <div className="col-12 form-group text-center text-md-start mb-3">
                                                        <img src={generated_qrcode} className='img-fluid qrcode-frame me-3 me-md-4' width={200} />
                                                    </div>
                                                    <div className="col-12 form-group text-center text-md-start">
                                                        <a download="qr-code-linkmo.jpg" href={generated_qrcode} className='btn btn-outline-primary' type='button'><Icons icon="download" classes='me-1' /> Download
                                                        </a>
                                                    </div>
                                                </div> : ''
                                            }
                                        </div>
                                        <div className="card-footer border-top-0 p-3 text-center">
                                            { shorten_url ?
                                            <button type='button' className='btn btn-lg btn-success' onClick={reset}><i className="fas fa-save mr-2" />Generate New</button> :
                                            <button type='button' className='btn btn-lg btn-primary' onClick={submit}>Shorten URL <Icons icon="arrow-contract" classes='ms-1' /></button>
                                            }
                                            <div className="row">
                                                {/* <div className="col-lg-8 mb-2 mt-2">
                                                    <ReCAPTCHA
                                                        ref={recaptchaRef}
                                                        sitekey="6LdmdwwpAAAAALXqUgq-F9Upm120UUepV-ohMlY9"
                                                        onChange={()=>removeErrors({target: {name:'recaptcha'}})}
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="recaptcha" 
                                                        className="text-danger"
                                                        style={{fontSize: '.8rem'}}
                                                    />
                                                </div>
                                                <div className="col-lg-4 d-flex align-items-center justify-content-end pt-2 pb-2">
                                                    <button type='button' className='btn btn-lg btn-primary' onClick={submit}><i className="fas fa-save mr-2" />Shorten URL</button>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-xs-12 col-lg-7 mb-3">
                                <div id="ads-dsiplay">

                                    {/* <blockquote className="blockquote">
                                        <h1>You must be the change you wish to see in the world.</h1>
                                    </blockquote>
                                    <figcaption className="blockquote-footer">
                                        Mahatma Gandhi
                                    </figcaption> */}
                                    {display_ads}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        }
        </>
    )
}
 
export default HomePage;