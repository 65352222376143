import appConfig from '../config/env';

const getToken = () => {
    if (localStorage.getItem('linkmo-token')) {
        return localStorage.getItem('linkmo-token')
    }

    return false
}

const isAuthenticated = () => {
    if (localStorage.getItem('linkmo-authenticated')) {
        return true
    }

    return false
}

const getUserName = () => {
    if (localStorage.getItem('linkmo-name')) {
        return localStorage.getItem('linkmo-name')
    }

    return false
}

const getPermissions = () => {
    if (localStorage.getItem('linkmo-permissions')) {
        return localStorage.getItem('linkmo-permissions')
    }

    return []
}

const getUrl = (slug = '') => {
    const url = appConfig.apiUrl + `/${slug}`;

    return url
}

function displayDomain(domains, id){
    let obj = domains.find(o => o.id == id);

    return obj.label
}

function isValidUrl(string) {
    try {
        const newUrl = new URL(string);
        return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
      } catch (err) {
        return false;
      }
  }
  

const helpers = {
    getToken,
    isAuthenticated,
    getUserName,
    getPermissions,
    getUrl,
    displayDomain,
    isValidUrl
}

export default helpers;