import React from 'react';
import rollin from '../../assets/img/rollin.gif';

const Loader = ({ isLoading }) => {

    if (!isLoading) {
        return "";
    }

    return (
        <div style={{ position: 'absolute', zIndex: 99, top: 0, left: 0, bottom: 0, right: 0, background:'rgba(237, 242, 247,0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <img src={rollin} style={{width: '50px', height: '50px'}}/>
        </div>
    );
}

export default Loader;