import React from 'react';
//import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
/**
 * Main Router
*/
import MainRoutes from './routes/Routes';

function App() {

  return (
      <div className="App">
          <MainRoutes />{/* All pages can be manage inside this components */}
      </div>
  );
}

export default App;
