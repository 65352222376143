import mainLogo from '../assets/img/Linkmo.co-main-logo.webp';
import burgerIcon from '../assets/img/burger-icon.svg';
import closeIcon from '../assets/img/menu-close.svg';

const images = {
    mainLogo,
    burgerIcon,
    closeIcon,
}

export default images