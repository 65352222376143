import { useLocation } from "react-router-dom";

export const includes = function (haystack, needle) {
    return haystack.indexOf(needle) >= 0;
}

export const html = function (data) {
    return {__html: data}
}

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}