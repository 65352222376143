import React from 'react';
import { Routes, Route } from "react-router-dom";

/** Component Pages Here */
import HomePage from '../components/pages/HomePage';
import AliasRouter from '../components/pages/AliasRouter';

function MainRoutes(){
    return (
        <Routes>
            <Route 
                exact path="/" 
                element={<HomePage />} 
            />

            <Route 
                exact path="/:alias" 
                element={<AliasRouter />} 
            />

            <Route 
                path="*" 
                element={<AliasRouter />} 
            />
        </Routes>
    )
}

export default MainRoutes;
