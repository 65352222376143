/**
 * Select Input Reusable Components
 */
import React from "react";

const DropDownSelection = ({
        id, 
        name, 
        value, 
        placeholder = '', 
        label='',
        labelClass='',
        optionList, 
        optionProps={},
        tagClass='',
        onChange, 
        onFocus,
        readOnly = false
    }) => {

    /** Dynamically access object property **/
    const opt_value = optionProps.value; 
    const opt_label = optionProps.label; 

    return (
        <>
            {label && <label htmlFor={id} className={labelClass}>{label}</label>}
            <select
                id={id}
                onFocus={(e) => onFocus(e)}
                onChange={(e) => onChange(e)}
                name={name}
                className={tagClass}
                value={value}
                readOnly={readOnly}
            >
                {/* Placeholder */}
                {/* {(placeholder!='') ? <option value="" disabled>{placeholder}</option> : ''} */}
                <option value="" disabled>{placeholder}</option>

                {optionList &&
                    optionList.length > 0 &&
                    optionList.map((option, key) => {
                        return (
                            <option key={key} value={option[opt_value]}>
                                {option[opt_label]}
                            </option>
                        );
                    })}
            </select>
        </>
    );
};

export default DropDownSelection;
